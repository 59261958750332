<template>
  <div class="d-flex flex-column justify-center align-center login-container">
    <v-container grid-list-xl>
      <v-layout row justify-center>
        <v-flex xs4 sm4 md4 xl3 class="cardLogin">
          <v-form @submit="submit">
            <v-text-field
              v-model="credentials.username"
              label="Usuário"
              required
              color="blue darken-2"
              :rules="usernameRules"
              @keyup.enter="submit"
            />
            <v-text-field
              v-model="credentials.password"
              label="Senha"
              required
              color="blue darken-2"
              :append-icon="showPassword ? mdiEye : mdiEyeOff"
              :rules="passwordRules"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="submit"
            />

              <v-progress-circular
              v-if="loading"
                :size="27"
                color="secondary"
                class="mr-9"
                indeterminate
              >
              </v-progress-circular>
            <v-btn v-else class="mr-4" @click="submit">
               Login
              </v-btn>
          </v-form>
        </v-flex>
        <v-flex xs4 sm3 md3 xl3 class="cardLogo ">
          <div class="p-4 d-flex justify-center">
            <v-img
              max-height="200"
              max-width="97"
              alt="Marca Conan"
              :src="require('../../assets/LogoLigth.png')"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <app-snackbar
      v-model="showSnackbar"
      color="error"
      text="Usuário ou Senha incorreta!"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mdiEye, mdiEyeOff, mdiClose } from "@mdi/js";
import { ROUTE_NAME } from "@/router";
import AppSnackbar from "@/components/AppSnackbar";

export default {
  name: "Login",
  components: { AppSnackbar },
  data() {
    return {
      loading: false,
      credentials: {
        username: "",
        password: "",
      },
      showSnackbar: false,
      showPassword: false,
      mdiEye,
      mdiEyeOff,
      mdiClose,
      usernameRules: [(value) => !!value || "Usuário é obrigatório."],
      passwordRules: [(value) => !!value || "Senha é obrigatória."],
    };
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    logoHeight() {
      return this.$vuetify.breakpoint.smAndDown ? "100" : "120";
    },
  },
  methods: {
    ...mapActions(["authenticate"]),
    async submit() {
      this.loading = true
      try {
        await this.authenticate(this.credentials);
        this.loading = false
        this.$router.push({
          name: ROUTE_NAME.OVERVIEW,
        });
      } catch (error) {
        this.showSnackbar = true;
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: calc(100vh - 64px);
}

form {
  max-width: 100%;
  text-align: right;
}

.cardLogin{
  background-color: #ffffff8f;
}
.cardLogo{
  background-color: #3a567dbf;
  .cardLogoTitle{
    color: #fff;
  }
}
</style>
